import React from 'react';
import Post from '../components/post';
import EvenFinancialWidget from '../components/EvenFinancialWidget';
import LazyLoadImage from '../components/LazyLoadImage';


const SixStepsOutOfDebtPage = () => {
    const article = {
        id: '71a7139c-1304-54d0-908e-f57910366ce7',
        title: 'The Simple 6-Step Process To Getting Out of Debt',
        slug: '/6-steps-out-of-debt/',
        date: '2018-01-28T19:00:05.000Z',
        modified: '2021-11-01T18:25:51.000Z',
        excerpt: 'Anyone can live debt-free. You just need to understand where your debt comes from, exactly how much you owe, and begin taking steps to pay off your principal.',
        featured_image: {
            source_url: '/media/bookkeeping.jpg',
        },
        acf: {
            seo_title: '',
            robots: 'index, follow',
        },
        read_time_minutes: 11,
        content: (
            <>
                <p>
                    The average consumer in the United States has roughly $7,800 in debt, and about a third of that is
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=6-steps-out-of-debt&amp;sub2=credit-card" rel="noopener noreferrer" target="_blank">credit card</a>
                    {' '}
                    debt.
                </p>
                <p>
                    Sometimes this debt is self-inflicted through poor
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=6-steps-out-of-debt&amp;sub2=credit-card" rel="noopener noreferrer" target="_blank">credit card</a>
                    {' '}
                    choices, but much of it stems from otherwise unavoidable costs —
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&amp;sub1=6-steps-out-of-debt&amp;sub2=student-loans" rel="noopener noreferrer" target="_blank">student loans</a>
                    , mortgages, etc.
                </p>
                <p>The danger comes from not fully understanding the terms of these loans.</p>
                <p>Debt affects you in more ways than one; in fact, there are numerous studies that suggest stress, anxiety, and other mental ailments are compounded by the presence of outstanding debt.</p>
                <p>Why let something controllable have such a profound negative impact on your life?</p>
                <p>Unless addressed and treated, debt will continue to grow.</p>
                <p>The national debt per household has grown six percent in the last year alone and continues on that upward tick. If you find yourself in a situation with increasing debt and you can’t see a light at the end of the tunnel, it’s time to act.</p>
                <p>Big financial decisions will affect you for years to come, so make them good ones. Even if you’ve tried to get out of debt before and failed, there’s hope.</p>
                <LazyLoadImage src="/media/debt-impacts-peoples-decision-andor-ability-to-2017-1.png" alt="Debt Impacts People’s Ability to..." />
                <p>This guide will help you create an achievable plan to get out of debt.</p>
                <p>Just start with one step from this list and see where it takes you.</p>
                <h2 id="step-1-understand-where-your-debt-comes-from">Step 1: Understand Where Your Debt Comes From</h2>
                <h3>The first step to becoming debt-free is understanding where your debt comes from in the first place.</h3>
                <p>For most consumers, the answer is easy: credit cards.</p>
                <p>
                    More than 33 percent of the national debt per capita is due to credit card balances, while the remaining debt is divided between
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&amp;sub1=6-steps-out-of-debt&amp;sub2=student-loans" rel="noopener noreferrer" target="_blank">student loans</a>
                    , mortgages, auto payments, and other obligations.
                </p>
                <p>The problem is the debt that follows you and revolves.</p>
                <p>Maybe you make a big payment toward your credit card one month, but then you have a lean period and begin to rely on your credit card even more.</p>
                <p>Understanding your debt is about more than just knowing what put you in debt in the first place — it also helps you stay out of debt when you do pay off your balances.</p>
                <p>To understand your debt, you need to know what triggers it.</p>
                <p>If you are an impulsive shopper, your credit cards might be an issue.</p>
                <p>Maybe you carry a full balance on them most of the time and end up using them as soon as you’ve made headway toward paying them off.</p>
                <p>If this is the case, consider leaving your credit cards at home when you go out.</p>
                <p>You can even take the more extreme, if movie-like step of freezing your credit cards.</p>
                <p>Make a conscious decision to stop borrowing money.</p>
                <p>This means that you don’t rely on credit for purchases. In other words, never spend money you do not currently have.</p>
                <p>If there is a big-ticket item you want to purchase, set aside a small amount of each paycheck until you can afford it.</p>
                <p>Doing so prohibits impulse purchases and teaches more financial self-control.</p>
                <h2 id="step-2-organize-your-debt">Step 2: Organize Your Debt</h2>
                <h3>Before you begin paying off your debts, you’ll need to know exactly how much you owe and where you owe it.</h3>
                <p>Sit down with a notebook and write down all of your outstanding debts.</p>
                <p>This will include any credit card balances, mortgage payments, auto loans, etc.</p>
                <p>Once you have written down all of these numbers, organize them from the smallest amount to the greatest amount.</p>
                <p>Make sure to also write down each payment’s respective APR beside the value.</p>
                <p>There are two conflicting schools of thought in debt repayment.</p>
                <LazyLoadImage src="/media/student-loan-repayment-plan-2017-2.png" alt="Student Loan Repayment Plan (2017)" />
                <p>The first suggests that you should pay off the smallest amount first, then move on to the next-smallest amount.</p>
                <p>The logic behind this thought pattern is that eliminating one balance will give you the momentum you need to continue paying off others; earning a small victory early on raises morale.</p>
                <p>Many people like to use this method for debt repayment, and it has its merits.</p>
                <p>Provided you don’t use the line of credit once it is paid off, it eliminates one of the payments you need to make each month.</p>
                <p>The other school of thought is to pay off the balance with the highest APR first.</p>
                <p>For example, if you have a $2,000 credit card balance with a $100 monthly payment, but you end up paying $30 in interest charges each month, you&rsquo;re actually only putting $70 toward the principal amount.</p>

                <p>That results in just over $850 more that you’re paying in interest alone — in many cases, that’s enough to pay off another credit card entirely.</p>
                <p>If you have a payment with a particularly high interest rate, this method can be beneficial.</p>
                <p>There is a third option that isn’t available to everyone.</p>
                <p>
                    One of the major hurdles in debt repayment is juggling multiple payments each month. If you qualify for a
                    {' '}
                    <a href="https://www.debtconsolidation.com/loans/">debt consolidation loan</a>
                    , you can use that
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=43&amp;sub1=6-steps-out-of-debt&amp;sub2=loan" rel="noopener noreferrer" target="_blank">loan</a>
                    {' '}
                    to pay off multiple outstanding balances at once and combine them all into a single monthly payment.
                </p>
                <p style={{
                    fontSize: '1.71428571rem', lineHeight: '1.28571429em', fontWeight: 700, marginTop: '31.5917px', marginBottom: '5px', textAlign: 'center',
                }}
                >
                    See If You Qualify for a Personal&nbsp;Loan up&nbsp;to&nbsp;$250k
                </p>
                <EvenFinancialWidget url="https://embed.hifiona.com/ui/multi-product/index.html?partner=debtconsolidationembed&amp;access_token=628217c7-f4c2-4006-a2b1-3031b4c21272_8362ee6d-9497-4440-aaf2-8c6e5685f90b&amp;company_uuid=65d1a0bd-1cab-43c4-b755-2a6bff913a03&amp;productType=loan&amp;pw_fiona=true&amp;available_products=loan" slug="6-step-out-of-debt" />
                <br />
                <p>This reduces the stress of paying back multiple balances each month while simultaneously reducing the amount of interest you’ll pay.</p>
                <p>Debt consolidation loans are not available to everyone, particularly people with damaged credit, but can be a powerful tool for making headway in debt repayment.</p>
                <h2 id="step-3-figure-out-your-cash-flow">Step 3: Figure Out Your Cash Flow</h2>
                <h3>Paying more than the minimum payment is absolutely essential for paying off debt in any reasonable amount of time.</h3>
                <p>Minimum payments are designed to keep you whittling away at the principal balance amount while taking the longest possible time to pay off a debt.</p>
                <p>Whenever possible, you should aim to pay more than the minimum.</p>
                <p>Paying off a card with a $2,000 balance while making just the minimum payment would take decades. Not only would the interest rates would impact how much you put toward the principal, but inflation will also impact your ability to repay.</p>
                <p>Do you want to spend nearly 30 years paying off a credit card?</p>
                <p>
                    Creating a debt repayment strategy is like a smaller version of this overall list. This takes time and effort, but it&rsquo;s a tried-and-true approach adopted by countless, including
                    {' '}
                    <a href="https://www.debtconsolidation.com/tyler-perry/">celebrities you have almost certainly heard of</a>
                    . You’ll start by making a list of your expenses.
                </p>
                <p>How much do you spend on your rent or mortgage each month? How much do you spend on your utility bills?</p>
                <p>All essential expenses should be listed out and then ranked from most expensive to least expensive.</p>
                <p>In many cases, you won’t be able to change these amounts — but if you have the ability to negotiate your utility payment or reduce the amount you spend, it’s worth exploring.</p>
                <p>
                    <strong>Consider your cash flow.</strong>
                    {' '}
                    If you work a standard nine-to-five, then you have a good idea how much money you make every two weeks or every month.
                </p>
                <LazyLoadImage src="/media/how-do-people-with-students-loans-live-3.png" alt="How do people with students loans live?" />
                <p>The amount of money you receive, and when you receive it, is your cash flow.</p>
                <p>If you work for yourself or in a commission-based field, your income may fluctuate.</p>
                <p>Calculate your average monthly earnings over the past six months and use that as your basis.</p>
                <p>
                    <strong>You should eliminate as many non-essential expenses as possible.</strong>
                    {' '}
                    No one is asking you to give up Netflix, but perhaps renting a movie from the local library is a better option than visiting the Redbox twice per week.
                </p>
                <p>When you reduce the amount you spend on unnecessary costs, you increase the amount you can put toward your debt.</p>
                <p>Even freeing $50 per month can help you pay off a credit card significantly faster.</p>
                <p>
                    <strong>Decide what debt to pay off first and then pay as much as possible toward that goal each month.</strong>
                    {' '}
                    Without putting yourself in harm&rsquo;s way financially, you should commit a large part of your disposable income toward paying off your debt.
                </p>
                <p>Once this first debt is paid off, move on to the second.</p>
                <p>
                    However, don’t forget to take at least 10% of each paycheck and put it toward an emergency
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=48&amp;sub1=6-steps-out-of-debt&amp;sub2=savings" rel="noopener noreferrer" target="_blank">savings</a>
                    {' '}
                    account.
                </p>
                <p>Having funds on hand to cover unexpected expenses will help you avoid dipping into credit cards.</p>
                <h2 id="step-4-set-achievable-milestones">Step 4: Set Achievable Milestones</h2>
                <p>Your current APR is not set in stone.</p>
                <p>
                    According to recent reports,
                    {' '}
                    <a href="https://www.cnbc.com/2017/03/27/asking-credit-card-companies-to-lower-rates-and-fees-works-80-of-the-time.html">69% of people who requested a lower rate from their credit card company received it</a>
                    .
                </p>
                <p>Credit card companies profit from interest payments, yes — but they also want to retain you as a customer and understand that drowning in debt does not encourage credit card use.</p>
                <p>
                    If your debt has reached an amount that you can’t foresee yourself overcoming, consider
                    {' '}
                    <a href="https://www.debtconsolidation.com/debt-settlement/">seeking debt settlement</a>
                    .
                </p>
                <p>
                    <strong>This same survey found that only half of all credit card holders ever request a lower rate.</strong>
                    {' '}
                    When you are working to regain control of your finances and your debt, you should use every weapon in your arsenal.
                </p>
                <p>Reach out to your credit card company and find out what sort of rate they would be willing to negotiate.</p>
                <p>If possible, bring your account current first; proving that you are actively working to reduce your debt acts as a show of good faith.</p>
                <p>Setting achievable milestones is an important part of debt repayment because it helps you measure your progress.</p>
                <p>It can often seem like you’re spinning your wheels without going anywhere, particularly if you are making smaller payments each month.</p>
                <p>Milestones let you see that you are actually moving forward, but there is more to setting milestones than just saying, “I’ve paid off my lowest balance. Go me.”</p>
                <p>For example, you can make one of your milestones something like paying off $6,000 of debt in six months.</p>
                <p>If you are making smaller payments, your milestone could be $3,000 of debt in six months.</p>
                <p>You could also use percentages to track your progress; for example, a milestone could be paying off 10% of your overall debt.</p>
                <p>When you reach these milestones, reward yourself.</p>
                <p>While it may be self-bribery, it helps keep your spirits up and keep you incentivized to continue paying off the debt.</p>
                <p>Splurge for dinner at your favorite restaurant or buy that new book you’ve had your eye on, but make sure your reward doesn’t put you back into debt.</p>
                <h2 id="step-5-eliminate-all-unnecessary-expenses">Step 5: Eliminate All Unnecessary Expenses</h2>
                <p>In an ideal world, this step would be simple — but we would also all live in homes without television or anything besides the strict necessities.</p>
                <p>
                    No one is asking you to give up
                    {' '}
                    <em>Game of Thrones</em>
                    , but there are steps you can take to reduce the amount you spend each month on luxury items.
                </p>
                <p>
                    <strong>List out all monthly subscriptions you have and decide which ones you don’t really need.</strong>
                    {' '}
                    Maybe you receive monthly massages. That’s an average of $60 per month, plus the $20 tip.
                </p>
                <p>In a year, that’s $960 on something that is purely a luxury.</p>
                <LazyLoadImage src="/media/where-did-you-get-your-last-massage-4.png" alt="Where did you get your last massage?" />
                <p>Unless you have a medical reason for regular massages, consider cutting those until you’ve taken care of your debt.</p>
                <p>In that same vein, consider if you really need cable.</p>
                <p>
                    According to studies,
                    {' '}
                    <a href="https://www.nbcnews.com/nightly-news/infographic-how-many-tv-channels-do-you-actually-watch-n99046">most people only consistent watch about 17 channels</a>
                    . Rather than paying $50 per month for a cable subscription, why not switch to Netflix or Hulu?
                </p>
                <p>There are also other alternatives like Youtube TV that, while not as inexpensive as a streaming service, provide a more economical alternative to traditional cable.</p>
                <p>
                    You don’t have to give up a comfortable home, but you can
                    {' '}
                    <a href="https://money.usnews.com/money/blogs/my-money/2014/02/21/9-ways-to-save-on-your-utility-bill">save 3% on your energy bill for every one degree you lower the thermostat</a>
                    .
                </p>
                <p>Make use of your energy company’s recommended temperatures to save a few dollars every month on your bill — those savings add up.</p>
                <p>The biggest offender for most people is dining out.</p>
                <p>It’s the age-old advice: cook for yourself, and you’ll save money.</p>
                <p>Of course, cooking larger meals and putting the leftovers in the fridge will save more than trying to cook something new for every single meal.</p>
                <p>Setting up a food budget and sticking with it — and eating out only on special occasions — will save thousands of dollars per year.</p>
                <p>Finally, coffee is costing you money.</p>
                <p>If you spend $4 per day on a cup of coffee, that comes out to $120 per month — or $1,440 per year.</p>
                <p>It’s much less expensive to brew your own coffee at home and take it with you each day. If you aren’t ready to give up your daily Starbucks habit completely, consider limiting it to just a few times per week.</p>
                <h2 id="step-6-set-up-an-emergency-fund">Step 6: Set Up an Emergency Fund</h2>
                <h3>Start yourself an emergency fund and start searching for alternative revenue streams</h3>
                <p>The famous Dave Ramsey suggests putting aside $1,000 immediately for an emergency fund.</p>
                <p>This amount won’t cover all expenses, but it will help close the gap if you ever find yourself not bringing in as much as you expected or facing an unexpected cost.</p>
                <p>Ultimately, you’ll want to grow your emergency fund to equal to six months of living expenses, but starting off with just $1,000 is an achievable goal.</p>
                <p>If you have alternative revenue streams, put these to use covering your debt.</p>
                <p>You don’t have to start a full-fledged business, but if you make a few bucks a month selling items on eBay or Etsy, you can use these additional funds to help knock out your debt even faster.</p>
                <p>If you don’t do either of these things, you might want to sell your old clothing or drive for Uber.</p>
                <p>If you are a freelance worker, increasing your workload for a short period can help you make additional funds fast.</p>
                <p>Even small amounts of extra funds can add up over time and help you eliminate your debt.</p>
                <p>Once you’ve whittled your principal amount down, start adding funds into your emergency fund.</p>
                <h2 id="debt-is-not-an-insurmountable-obstacle">Debt is not an insurmountable obstacle</h2>
                <p>Make this your mantra: anyone can be debt-free.</p>
                <p>
                    It won’t happen overnight, and it won’t happen accidentally — but with
                    {' '}
                    <a href="https://www.debtconsolidation.com/how-to-get-out-of-debt/">concentrated, determined effort</a>
                    , anyone can rid themselves of the burden of debt and live a happier life.
                </p>
                <p>These six steps will help you regain financial control of your life. You don’t have to apply them all at once; start with whichever step is easiest for you and build out from there.</p>
                <p>The ultimate goal is not only to eliminate your debt, but to rid yourself of the thought processes that land people in debt in the first place.</p>
                <LazyLoadImage src="/media/who-thinks-debt-has-a-negative-affect-on-everyday-life-5.png" alt="Who thinks debt has a negative affect on everyday life?" />
                <p>Change the way you think about finances and you greatly reduce the risk of falling back into debt again.</p>
                <p>Have you used these methods (or similar ones) to become debt-free?</p>
                <p>How did it work for you?</p>
                <p>Do you have any tips and advice to share?</p>
                <p>Let us know in the comments below.</p>
            </>
        ),
    };

    return (
        <Post article={article} />
    );
};

export default SixStepsOutOfDebtPage;
